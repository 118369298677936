<template>
  <div class="wheel_wrap">
    <GluecksRad 
      :wheeldata="wheeldata" 
      class="radContainer"
      v-if="showLucky === true" 
      ref="luckyW"
    ></GluecksRad>
  </div>
</template>

<script>
import GluecksRad from "@/components/GluecksRad.vue"

import {mapGetters} from "vuex";
import { mapActions } from "vuex";

export default {
  name: 'WheelFortune',

  components: {
    GluecksRad,
  },

 props: ['projectName'],

  data() {
    return {
      showLucky: false,
    }
  },

  //created() {
   // console.log(' - wheelForune - created !')
  //},

  async mounted() {
  //   this.loadGiftStats();
 //    await this.loadLuckyLocal(this.projectName)
     await this.loadLuckyFromServer(this.projectName)
    //this.showQrIfSelected()
    this.showLucky = true;
    console.log(' - wheelForune - mounted !')
  },

  computed: {
     ...mapGetters([
       'wheeldata',
    ]),
  },

  methods: {
     ...mapActions([
      'loadLuckyFromServer',
      'loadLuckyLocal',
    ]),

   
  }
}
</script>

<style scoped>
</style>
